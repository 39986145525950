/* BluuNext */

@font-face {
  font-family: "bluu";
  src: url("../webfonts/bluu/bluunext-bold-webfont.woff2") format("woff2"),
    url("../webfonts/bluu/bluunext-bold-webfont.woff") format("woff"),
    url("../webfonts/bluu/bluunext-bold.ttf") format("truetype"),
    url("../webfonts/bluu/bluunext-bold-webfont.svg#bluu_nextbold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bluu";
  src: url("../webfonts/bluu/bluunext-bolditalic-webfont.woff2") format("woff2"),
    url("../webfonts/bluu/bluunext-bolditalic-webfont.woff") format("woff"),
    url("../webfonts/bluu/bluunext-bolditalic.ttf") format("truetype"),
    url("../webfonts/bluu/bluunext-bolditalic-webfont.svg#bluu_nextbold_italic")
      format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "bluu_nexttitling";
  src: url("../webfonts/bluu/BluuNext-Titling.ttf") format("truetype"),
    url("../webfonts/bluu/bluunext-titling.woff2") format("woff2"),
    url("../webfonts/bluu/bluunext-titling.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* Cormorant Garamond */
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap");
