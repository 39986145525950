@use "../../assets/scss/font-variables" as fnt-v;
@use "../../assets/scss/colors.scss" as clr;

.history {
  .content {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: baseline;
    flex-wrap: wrap;
    gap: 2em;

    // div {
    //   // flex: 1;
    //   max-width: 600px;
    //   flex-grow: 1;
    // }
    .history_card {
      min-width: 315px;
      width: 100%;
      max-width: 600px;

      ul {
        flex: 1;
      }
    }

    // aside {
    //   width: 100%;
    //   max-width: 500px;
    //   background-color: lighten(clr.$primary, 30);
    //   aspect-ratio: 1;
    //   border-radius: 0.4em;
    //   padding: 0.4em;
    //   overflow: hidden;
    //   border: solid 3px clr.$primary;
    //   color: clr.$light;
    // }
  }
}

.history_card {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid clr.$primary;
  padding: 1.4em 0;
  gap: 0.5em;

  h4 {
    font-family: fnt-v.$ff-secondary;
    text-transform: capitalize;
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 0.9rem;
      text-align: left;
      font-family: fnt-v.$ff-primary;
      font-weight: fnt-v.$fw-medium;
    }
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    p:first-child {
      font-weight: fnt-v.$fw-medium;
      text-transform: capitalize;
    }

    p:last-child {
      font-family: fnt-v.$ff-primary;
      font-weight: fnt-v.$fw-semibold;
      font-size: 0.96rem;
    }
  }
  ul {
    display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 0.3em;
    padding-inline: 0.2em;
    font-weight: fnt-v.$fw-regular;
  }

  li::before {
    content: "";
    width: 5px;
    height: 5px;
    background-color: clr.$secondary;
  }
}
