// green-land
// $light: #f2e8cf;
// $dark: #386641;
// $primary: #a7c957;
// $secondary: #6a994e;

// red-hot
// $light: #f4d58d;
// $dark: #001427;
// $primary: #708d81;
// $secondary: #bf0603;

// choco-gray
// $light: #fffcf2;
// $dark: #252422;
// $primary: #ccc5b9;
// $secondary: #403d39;

// bluey
// $light: #eeeeee;
// $dark: #000000;
// $primary: #34b3f1;
// $secondary: #f15412;
$light: #eeeeee;
$dark: #000000;
$primary: #3bb78f;
$secondary: #0bab64;

// vento
// $light: #f1eee9;
// $dark: #15133c;
// $primary: #73777b;
// $secondary: #ec994b;

// vento-light
// $light: #eeedde;
// $dark: #141e27;
// $primary: #e0ddaa;
// $secondary: #203239;

// orange
// $light: #faf3e3;
// $dark: #000000;
// $primary: #ff9f29;
// $secondary: #1a4d2e;
