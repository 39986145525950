@use "./assets/scss/font";
@use "./assets/scss/colors" as clr;
@use "./assets/scss/font-variables.scss" as fnt-v;
@use "./assets/scss/class-utils.scss";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  @media screen and (prefer-reduced-motion: reduce), (update: slow) {
    animation-duration: 0s !important;
    animation-delay: 0s !important;
    animation-iteration-count: 0s !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}

:root {
  font-size: 1rem;

  @media (min-width: 769px) {
    font-size: 1.1rem;
  }
}

/* Colors */
.bluey {
  // bluey
  $light: #eeeeee;
  $dark: #000000;
  $primary: #34b3f1;
  $secondary: #f15412;
}
.vento {
  // vento
  $light: #f1eee9;
  $dark: #15133c;
  $primary: #73777b;
  $secondary: #ec994b;
}
.vento-light {
  // vento-light
  $light: #eeedde;
  $dark: #141e27;
  $primary: #e0ddaa;
  $secondary: #203239;
}
.choco-gray {
  // choco-gray
  $light: #fffcf2;
  $dark: #252422;
  $primary: #ccc5b9;
  $secondary: #403d39;
}
.red-hot {
  // red-hot
  $light: #f4d58d;
  $dark: #001427;
  $primary: #708d81;
  $secondary: #bf0603;
}
.green-land {
  // green-land
  $light: #f2e8cf;
  $dark: #386641;
  $primary: #a7c957;
  $secondary: #6a994e;
}
.orange {
  // orange
  $light: #faf3e3;
  $dark: #000000;
  $primary: #ff9f29;
  $secondary: #1a4d2e;
}

.rowflex {
  display: flex;
  gap: 1em;
}

/* Colors End */

body {
  font-family: fnt-v.$ff-primary;
  font-weight: fnt-v.$fw-medium;
  font-size: 1.1rem;
  line-height: 1.4;
  background-color: clr.$light;
  color: clr.$dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p a {
  color: clr.$dark;
  text-decoration: underline;
  font-family: fnt-v.$ff-primary;
  font-weight: fnt-v.$fw-bold;
  text-transform: capitalize;
}

img,
picture {
  display: block;
  max-width: 100%;
}

mark {
  background-color: clr.$dark;
  padding-inline: 0.2em;
  // color: clr.$primary;
  color: white;
}

iframe {
  width: 100%;
  height: 100%;
}
