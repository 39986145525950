@use "../../assets/scss/font-variables" as fnt-v;
@use "../../assets/scss/colors" as clr;

.navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-block: 1rem;
  position: relative;
  height: 60px;
}

.navlogo {
  text-decoration: none;
  text-align: left;
  color: clr.$dark;
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  .name {
    font-family: fnt-v.$ff-secondary-tilting;
    font-size: 1.9rem;
    line-height: 1.1;
  }

  .specialty {
    font-family: fnt-v.$ff-secondary;
    font-size: 0.8rem;
    line-height: 0;
    padding-top: 5px;
  }

  @media (min-width: 769px) {
    .name {
      font-size: 2.2rem;
    }
    .specialty {
      font-size: 0.95rem;
    }
  }
}

.nav_menu {
  position: absolute;
  top: 3em;
  right: 1em;
  // background-color: clr.$primary;
  background-color: clr.$light;
  color: clr.$light;
  padding: 0.5em 2em;
  min-height: 100px;
  border: solid 2px clr.$secondary;
  border-radius: 0.3em;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;

  &.active {
    display: flex;
  }

  > a {
    font-family: fnt-v.$ff-secondary;
    // color: clr.$dark;
    color: clr.$secondary;
    position: relative;
    text-decoration: none;
    transition: all 0.3s ease-in;

    &:hover,
    &:focus {
      color: clr.$primary;
      text-decoration: underline;
    }
  }

  @media (min-width: 769px) {
    position: static;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
    padding: 0;
    min-height: auto;
    border: none;
    border-radius: 0;
    background-color: inherit;
  }
}

.toggle {
  height: 80%;
  aspect-ratio: 1;
  display: grid;
  gap: 0.4em;
  place-content: center;
  cursor: pointer;
  z-index: 4;

  .bar {
    display: block;
    height: 0.2em;
    width: 1.8em;
    border-radius: 0.3em;
    background-color: clr.$dark;

    &:nth-child(2) {
      background-color: clr.$primary;
    }
  }

  @media (min-width: 769px) {
    display: none;
  }
}
