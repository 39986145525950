@use "../../assets/scss/colors" as clr;
@use "../../assets/scss/font-variables" as fnt-v;

%main_button {
  background-color: clr.$primary;
  border: solid 2px clr.$secondary;
  border-radius: 0.2em;
  padding: 0.5em 0;
  font-family: inherit;
  text-transform: capitalize;
  font-weight: fnt-v.$fw-semibold;
  font-size: 1rem;
  font-family: fnt-v.$ff-secondary;
  font-style: italic;
  // color: clr.$light;
  color: white;
  cursor: pointer;
  // margin: 0 0.5em;
  transition: border-radius 0.2s ease-in-out;

  & > * {
    padding: 0 calc(0.5em + 4px);
  }

  a {
    height: inherit;
    text-decoration: none;
    color: inherit;
  }
}

.button {
  @extend %main_button;
}

.outline {
  @extend %main_button;
  background-color: transparent;
  color: clr.$secondary;
}
