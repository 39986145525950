@use "../../assets/scss/font-variables.scss" as fnt-v;
@use "../../assets/scss/colors.scss" as clr;

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  & > * {
    flex: 1;
  }

  & > div:first-child {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    a {
      font-family: fnt-v.$ff-secondary;
      color: clr.$dark;
      text-decoration: none;
      font-size: 0.95rem;

      &:hover,
      &:focus {
        color: clr.$primary;
        text-decoration: underline;
      }
    }
  }

  & > div:nth-child(2) {
    display: block;
    min-height: 100px;
    min-width: 200px;
    border-bottom: clr.$secondary solid 4px;

    img {
      width: 100%;
    }
  }

  & > div:nth-child(3) {
    & > p {
      padding: 0.5rem 0;
    }
    p:first-child {
      font-family: fnt-v.$ff-secondary-tilting;
      text-align: left;
      font-size: 1.2rem;

      span {
        position: relative;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;
        font-weight: 400;
        font-size: 3.5rem;
        line-height: 0.76;
        padding: 0.5em;
        border: solid 2px clr.$secondary;
        margin-right: 0.2em;
      }
    }
    p:last-child {
      text-align: justify;
    }

    div:last-child {
      justify-content: center;
    }
  }

  @media (min-width: 769px) {
    margin-top: 2em;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-around;
    gap: 0.5em;
    min-height: 50vh;

    & > * {
      flex: auto;
    }

    & > div:first-child {
      flex-direction: column;
      gap: 1em;
      max-width: 90px;
      align-items: flex-start;
      text-align: left;
    }

    & > div:nth-child(2) {
      display: block;
      max-width: 300px;
      order: 1;
      // border: solid 3px clr.$secondary;
      // border-radius: 0.3em;
    }

    & > div:nth-child(3) {
      max-width: 700px;
      align-self: center;

      div:last-child {
        justify-content: flex-start;
      }
    }
  }
}
