@use "../../assets/scss/font-variables" as fnt-v;
@use "../../assets/scss/colors" as clr;

.projects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-self: center;
  gap: 1em;
  //   margin: 5em 0;
}

.project {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1em;
  padding: 0.5em;
  min-width: 355px;
  width: 100%;
  max-width: 400px;

  > div:first-child {
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.3em;

    span {
      font-size: 0.95rem;
      font-family: fnt-v.$ff-primary;
      font-weight: fnt-v.$fw-bold;
      padding: 0.3em 0.5em;
      border-radius: 0.3em;
      background-color: clr.$dark;
      // color: clr.$primary;
      color: white;
    }
  }

  > div:nth-child(2) {
    flex: 1;
  }

  .icon {
    position: relative;
    top: 0;
    float: left;
    background-color: transparent;
    width: 2.7em;
    height: 2.7em;
    overflow: hidden;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 0.3em;
  }

  h3 {
    font-family: fnt-v.$ff-secondary;
  }

  .stack {
    font-weight: fnt-v.$fw-semibold;
    opacity: 0.7;
    font-size: 0.9rem;
  }

  .description {
    text-align: left;
    padding: 0.5em 0;
  }

  a {
    font-weight: fnt-v.$fw-semibold;
    text-decoration: none;
    color: clr.$secondary;
    text-transform: lowercase;
    transition: all 0.3s ease-in-out;

    span {
      display: none;
      opacity: 0;
    }

    &:hover,
    &:focus {
      span {
        display: inline;
        opacity: 1;
      }
    }
  }
}

.misc_projects {
  h2 {
    font-family: fnt-v.$ff-secondary-tilting;
    text-align: center;
    margin-block: 1em;
    display: flex;
    align-items: center;
    gap: 0.5em;

    &::before,
    &::after {
      content: "";
      height: 1px;
      width: 100%;
      flex: 1;
      background-color: clr.$primary;
    }
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
  }
}
