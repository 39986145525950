@use "../../assets/scss/colors" as clr;
@use "../../assets/scss/font-variables" as fnt-v;

.footer {
  padding: 2em 0;
  border-top: dashed clr.$primary 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: fnt-v.$ff-secondary;

  .me {
  }

  a {
    text-decoration: none;
    color: clr.$secondary;
  }

  div {
    a:not(a:first-child) {
      margin-left: 1em;
    }
  }
}
