@use "../../assets/scss/colors" as clr;
@use "../../assets/scss/font-variables" as fnt-v;

.skills {
  .showcase {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 1em;
    flex-wrap: wrap;

    > div:first-child {
      max-width: 700px;

      p {
        margin-inline: auto;
        margin-block: 0.4em;
      }
    }

    aside {
      max-width: 500px;
      width: 100%;
      background-color: lighten(clr.$primary, 30);
      aspect-ratio: 1;
      border-radius: 0.4em;
      padding: 0.4em;
      overflow: hidden;
      border: solid 3px clr.$primary;
      color: clr.$dark;
    }
  }
}

.stack_list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5em;
  flex-wrap: wrap;
  text-align: justify;

  .stack {
    // margin-top: 1em;
    font-family: fnt-v.$ff-secondary;
    // background-color: lighten(clr.$primary, 30);
    background-color: clr.$dark;
    padding: 0.35em 0.9em;
    border-radius: 0.3em;
    border: solid 1px clr.$primary;
    color: white;
    transition: all 0.25s ease-in-out;

    &:hover {
      background-color: clr.$light;
      color: clr.$dark;
    }
  }

  @media (min-width: 769px) {
    text-align: left;
  }
}
