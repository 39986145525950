@use "./font-variables" as fnt-v;
@use "./colors" as clr;

.wrapper {
  max-width: 1440px;
  padding-inline: 1rem;
  margin-inline: auto;
}

.section-header {
  display: block;
  font-family: fnt-v.$ff-secondary-tilting;
  text-transform: uppercase;
  line-height: 0.9;
  text-align: center;
  margin: 1.5em 0;

  & > span {
    font-size: calc(1rem + 9vw);
    & > span {
      display: inline-block;
    }
    & > span:last-child {
      color: clr.$primary;
    }
    // font-size: calc(1rem + 15vw);
  }
}
