@use "../../assets/scss/font-variables" as fnt-v;
@use "../../assets/scss/colors" as clr;

.contact {
  text-align: center;
  margin-block: 2em;

  h2 {
    font-family: fnt-v.$ff-secondary-tilting;
    font-size: 2em;
    margin-bottom: 0.5em;
  }
}

.contactform {
  max-width: 500px;
  margin-top: 1em;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;

  input,
  textarea {
    outline: 0;
    padding: 0.5rem 0.3rem;
    border-radius: 0.3rem;
    border: solid 1px clr.$secondary;
    width: 100%;
    font-family: fnt-v.$ff-secondary-tilting;
    font-size: 1.1rem;
  }

  input[type="submit"] {
    background-color: clr.$secondary;
    color: white;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }
}
